import { Component } from '@angular/core';
import { Page } from '@http-handler/common/contracts/page';
import { BulkOrder } from '@models/bulk-order.model';
import { BulkOrderService } from '@services/bulk-order.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'admin-setup';

  constructor() {
  
  }


}
