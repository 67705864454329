import { Businesses } from './businesses.model';

export class User {
  id?: number;
  address?: string;
  city?: string;
  country?: string;
  countryCode?: string;
  createdAt?: string;
  dob?: any;
  email?: string;
  fullName?: string;
  firstName?: string;
  alternatePhone?:number;
  userId?: string;
  imgUrl?: any;
  ipAddress?: string;
  isEmailVerified?: boolean;
  lastName?: string;
  loginType?: string;
  name?: string;
  phone?: number;
  postalCode?: string;
  role?: Role;
  state?: string;
  status?: string;
  accessToken?: string;
  gender?: string;
  age?: any;
  uniqueId?: string;
  business?: Businesses;
  dailyWage?: any;
  // noOfService?: number;
}
export class Role {
  id?: any;
  name?: string;
  email?: any;
}
