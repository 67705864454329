import { Injectable } from '@angular/core';
import { LocalStorage } from '@core/constants/local-storage.enum';
import { User } from '@models/user.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  businesses: any;
  get token() {
    return localStorage.getItem(LocalStorage.TOKEN) || '';
  }

  set token(token: string) {
    localStorage.setItem(LocalStorage.TOKEN, token);
  }

  get user() {
    if (localStorage?.getItem(LocalStorage.USER)) {
      return JSON.parse(localStorage?.getItem(LocalStorage.USER) || '');
    } else {
      return new User();
    }
  }

  set user(u: User) {
    localStorage.setItem(LocalStorage.USER, JSON.stringify(u));
  }
}
